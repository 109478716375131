/* eslint-disable jsx-a11y/alt-text */
import { PictureAsPdf, Print } from '@mui/icons-material';
import { Checkbox } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import { React, useEffect, useRef, useState } from 'react';
import ConfirmModal from '../../shared/components/modals/Confirm';
import MassiveActionsModal from '../../shared/components/modals/MassiveActions';
import PdfViewerModal from '../../shared/components/modals/PdfViewer';
import PiezaModal from '../../shared/components/modals/PiezaInfo';
import DashboardService from '../../../../../services/dashboard';
import { getActions } from './actions';
import ActionsMenu from '../../shared/components/ActionsMenu';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import CustomDatePicker from '../../shared/components/CustomDatePicker';
import PiezaPreview from '../../shared/components/PiezaPreview';
import InfoModal from '../../shared/components/modals/Info';
import UserSelector from './UserSelector';
import { API_PEDIDOS } from '../../shared/services/pedidos';
import { toast } from 'react-toastify';
import { getStatusPedido, setErrorMessage } from '../../shared/helpers/functionalities';
import EtiquetaForm from './EtiquetaForm';
import LanguageSelector from './LanguageSelector';
import LogsTable from './LogsTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import ReactDOMServer from "react-dom/server";
import html2pdf from 'html2pdf.js/dist/html2pdf.min';
import AlbaranES from '../../../../../services/pdf/templates/albaran/albaranES';
import AlbaranEN from '../../../../../services/pdf/templates/albaran/albaranEN';
import TransportistaSelector from './TransportistaSelector';
import PiezasSelector from './PiezasSelector';

export default function PedidosTable({ 
  id,
  page,
  filterStatus,
  filterValues,
  lastUpdated,
  pedidos,
  userId,
  isAdmin,
  onPageChange,
  onFilterChange,
  onStatusChange 
}) {
  const estados = useSelector(state => {
    const { estados } = state;
    return estados;
  });
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const dispatch = useDispatch();
  const dashboard = useSelector(state => {
    return state.dashboard.entity
  }); 
  const [filteredPedidos, setFilteredPedidos] = useState([]);
  const [modalPiezaOpen, setModalPiezaOpen] = useState(false);
  const [modalUserOpen, setModalUserOpen] = useState(false);
  const [selectedPieza, setSelectedPieza] = useState(null);
  const [selectedPedido, setSelectedPedido] = useState(null);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [massEditMode, setMassEditMode] = useState(false);
  const [selectedPedidos, setSelectedPedidos] = useState([]);
  const [filtersVisible, setFiltersVisible] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [modalMassiveActionsOpen, setModalMassiveActionsOpen] = useState(false);

  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [confirmDesasignModalOpen, setConfirmDesasignModalOpen] = useState(false);
  const [confirmModalAction, setConfirmModalAction] = useState(null);
  const [confirmModalPedido, setConfirmModalPedido] = useState(null);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [openSelectAlbaranLanguage, setOpenSelectAlbaranLanguage] = useState(false);
  const [openLogs, setOpenLogs] = useState(false);
  const [openAssignTransportista, setOpenAssignTransportista] = useState(false);
  const [openCancelarPiezas, setOpenCancelarPiezas] = useState(false);
  const [openConfirmDeleteEtiqueta, setOpenConfirmDeleteEtiqueta] = useState(false);

  useEffect(() => {
    if(pedidos) {
      setFilteredPedidos(pedidos);
    }
  }, [lastUpdated, pedidos, filterStatus, filterValues]);

  const handleChangePage = (event, newPage) => {
    onPageChange(newPage);
  };

  const getPaginationCount = () => {
    const isFilterEmpty = Object.values(filterValues).every(x => x === null || x === '' || (x === 1 || x === 0 ? true : false));
    const total = isFilterEmpty ? (dashboard?.pedidos?.count ? dashboard?.pedidos?.count : 1) : filteredPedidos.length;
    var totalPages = total < 15 ? 1 : Math.ceil(total / 15);
    return parseInt(totalPages);
  }

  const handleOpenModalPieza = (pieza) => {
    setSelectedPieza(pieza);
    setModalPiezaOpen(true);
  };

  const handleOpenAsignarPedido = (pedido) => {
    setSelectedPedido(pedido);
    setModalUserOpen(true);
  };

  const handleOpenDesasignarPedido = (pedido) => {
    setSelectedPedido(pedido);
    setConfirmDesasignModalOpen(true);
  }

  const handleOpenModalMassiveActions = () => {
    setModalMassiveActionsOpen(true);
  };

  const handleCloseModalMassiveActions = () => {
    setModalMassiveActionsOpen(false);
  };

  const handlePreviewEtiqueta = (pedido) => {
    setSelectedPedido(pedido);
    setPdfViewerOpen(true);
  };

  const handleClosePdfViewer = () => {
    setSelectedPedido(null);
    setPdfViewerOpen(false);
  };

  const handleCloseModalPieza = () => {
    setModalPiezaOpen(false);
    setSelectedPieza(null);
  };

  const handleCloseModalUser = () => {
    setModalUserOpen(false);
    setSelectedPedido(null);
  };

  const handleConfirmAction = (confirm) => {
    setConfirmModalOpen(false);
    if (confirm && confirmModalAction && confirmModalPedido) {
      executeAction(confirmModalAction, confirmModalPedido);
    }
  };

  const handleImprimirEtiqueta = async(pedido) => {
    dispatch({type: 'loading/set', payload: true});
    const etiquetas = await DashboardService.handleImprimirEtiqueta(pedido)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });

    dispatch({type: 'loading/set', payload: false});
  }

  const handleSolicitarEtiqueta = async(pedido) => {
    dispatch({type: 'loading/set', payload: true});
    await DashboardService.handleSolicitarEtiqueta(
      pedido
    ).catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });
    dispatch({type: 'loading/set', payload: false});
  }

  const handleOpenCancelarPiezas = (pedido) => {
    setSelectedPedido(pedido);
    setOpenCancelarPiezas(true);
  }
  const handleCloseCancelarPiezas = () => {
    setOpenCancelarPiezas(false);
    setSelectedPedido(null);
  }
  const handleCancelarPiezas = async(piezas) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.cancelarPiezas(piezas, selectedPedido.id)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      toast.success(response.message);
      dispatch({type: 'loading/set', payload: false});
      handleCloseCancelarPiezas();
      onFilterChange(page);
    }
  }

  const handleOpenConfirmDeleteEtiqueta = (pedido) => {
    setSelectedPedido(pedido);
    setOpenConfirmDeleteEtiqueta(true);
  }
  const handleCloseDeleteEtiqueta = () => {
    setOpenConfirmDeleteEtiqueta(false);
    setSelectedPedido(null);
  }
  const handleDeleteEtiqueta = async(confirm) => {
    if(confirm) {
      dispatch({type: 'loading/set', payload: true});
      const response = await API_PEDIDOS.eliminarEtiquetaEnvio(selectedPedido.id)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error.message))
      });

      if(response && response.success) {
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        handleCloseDeleteEtiqueta();
        onFilterChange(page);
      }
    }

    handleCloseDeleteEtiqueta();
  }

  const executeAction = (action, pedido) => {
    if (action === 'imprimir_etiqueta') {
      handleImprimirEtiqueta(pedido);
    } else if (action === 'preview_etiqueta') {
      handlePreviewEtiqueta(pedido);
    } else if (action === 'remove_etiqueta') {
      handleOpenConfirmDeleteEtiqueta(pedido);
    } else if (action === 'pedido_descargado') {
      handleDescargarPedido(pedido);
    } else if (action === 'pedido_rechazado') {
      handleEliminarPedido(pedido);
    } else if (action === 'pedido_completado') {
      handlePedidoCompletado(pedido);
    } else if (action === 'recuperar_pedido') {
      const lastLogBeforeCancel = pedido.logs[pedido.logs.length - 2];
      actualizarPedido(pedido, pedido.user_id, lastLogBeforeCancel.estado_id);
    } else if (action === 'asignar_pedido') {
      handleOpenAsignarPedido(pedido);
    } else if (action === 'desasignar_pedido') {
      handleOpenDesasignarPedido(pedido);
    } else if (action === 'solicitar_etiqueta') {
      handleSolicitarEtiqueta(pedido);
    } else if (action === 'subir_etiqueta') {
      handleOpenUploadModal(pedido)
    } else if (action === 'logs') {
      handleOpenLogs(pedido)
    } else if(action === 'asignar_transportista') {
      handleOpenAssignTransportista(pedido)
    } else if(action === 'cancelar_piezas') {
      handleOpenCancelarPiezas(pedido)
    }
  };

  const handleAction = (action, pedido) => {
    if (
      ['pedido_rechazado', 'pedido_completado', 'asignar_pedido'].includes(action) &&
      pedido.piezas.length > 1
    ) {
      setConfirmModalAction(action);
      setConfirmModalPedido(pedido);
      setConfirmModalOpen(true);
    } else {
      executeAction(action, pedido);
    }
  };

  const handleOpenLogs = (pedido) => {
    setSelectedPedido(pedido);
    setOpenLogs(true);
  }
  const handleCloseLogs = () => {
    setOpenLogs(false);
    setSelectedPedido(null);
  }

  const handleOpenUploadModal = (pedido) => {
    setSelectedPedido(pedido);
    setUploadModalOpen(true);
  }
  const handleCloseUploadModal = () => {
    setUploadModalOpen(false);
    setSelectedPedido(null);
  }
  const handleSaveEtiqueta = async(data) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.uploadEtiqueta(data, selectedPedido.id)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseUploadModal();
      onFilterChange(page);
    }
  }

  const handleOpenAssignTransportista = (pedido) => {
    setSelectedPedido(pedido);
    setOpenAssignTransportista(true);
  }
  const handleCloseAssignTransportista = () => {
    setOpenAssignTransportista(false);
    setSelectedPedido(null);
  }
  const handleAssignTransportista = async(transportistaId) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.updateTransportistaPedido(selectedPedido.id, {
      transportista_id: transportistaId
    }).catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error.message))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});
      toast.success(response.message);
      handleCloseAssignTransportista();
      onFilterChange(page);
    }
  }

  const crearAlbaranPedido = async(pedido, idRefPieza, language) => {
    dispatch({type: 'loading/set', payload: true});
    const response = await API_PEDIDOS.getAlbaranInfo(pedido.id, idRefPieza)
    .catch(function (error) {
      dispatch({type: 'loading/set', payload: false});
      toast.error(setErrorMessage(error))
    });

    if(response && response.success) {
      dispatch({type: 'loading/set', payload: false});

      const pdfTemplate = language === 'es' ? AlbaranES(response.data) 
        : AlbaranEN(response.data);

      const printElement = ReactDOMServer.renderToString(pdfTemplate);
      html2pdf().from(printElement).save(`albaran_${pedido.numero_presupuesto}.pdf`);

      toast.success('Albaran generado correctamente!')
      handleCloseSelectAlbaranLanguage();
    } else {
      toast.error('Ups! Algo ha ido mal...')
    }
  }

  const handleEliminarPedido = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      6 // RECHAZADO
    );
  };

  const handleDescargarPedido = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      2 // DESCARGADO
    );
  }

  const handlePedidoCompletado = (pedido) => {
    actualizarPedido(
      pedido,
      userId,
      4 // COMPLETADO
    );
  };

  const handleAsignarUsuario = (userId) => {
    actualizarPedido(
      selectedPedido,
      userId,
      3 // ASIGNADO
    );
    setSelectedPedido(null);
  };

  const handleConfirmDesasignAction = async(confirm) => {
    if(confirm) {
      actualizarPedido(
        selectedPedido,
        null,
        selectedPedido.estado_id
      );
    }

    setSelectedPedido(null);
    setConfirmDesasignModalOpen(false);
  }

  const actualizarPedido = async (pedido, userId, estadoId) => {
    try {
      dispatch({type: 'loading/set', payload: true});
      const body = {
        user_id: userId,
        estado_id: estadoId
      };
      
      const response = await API_PEDIDOS.updatePedido(pedido.id, body)
      .catch(function (error) {
        dispatch({type: 'loading/set', payload: false});
        toast.error(setErrorMessage(error))
      });

      if (response.success) {
        toast.success(response.message);
        dispatch({type: 'loading/set', payload: false});
        handleCloseModalUser();
        onFilterChange(1)
      }
    } catch (error) {
      console.error('Error updating pedido:', error);
    }
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    onFilterChange(page, { [name]: value });
  };

  const handleChangeStatus = (e) => { onStatusChange(parseInt(e.target.value)) }

  const clearFilters = () => {
    onFilterChange(page, null);
  };

  const toggleMassEditMode = () => {
    setMassEditMode(!massEditMode);
    if (massEditMode) {
      setSelectAllChecked(false);
      setSelectedPedidos([]);
    }
  };

  const handleSelectPedido = (pedido) => {
    if (selectedPedidos.find(p => p.origin_id === pedido.origin_id)) {
      setSelectedPedidos(selectedPedidos.filter(p => p.origin_id !== pedido.origin_id));
    } else {
      setSelectedPedidos([...selectedPedidos, pedido]);
    }
  };

  const handleExecuteMassiveAction = () => {
    if (!isAdmin) return;

    // Open the modal to select the massive action
    handleOpenModalMassiveActions();
  };

  const handleFinalizeMassiveAction = async (options) => {
    dispatch({type: 'loading/set', payload: true});
    console.log('Selected Massive Action:', options);

    if (options.imprimir) {
      const selectedPedidosToPrint = pedidos.filter((pedido) =>
        selectedPedidos.find(p => p.origin_id === pedido.origin_id)
      );
      await DashboardService.handleImprimirEtiquetaMassiva(
        selectedPedidosToPrint
      );
    }

    if (
      options.marcar.completado ||
      options.marcar.rechazado ||
      options.assignar.habilitado
    ) {
      const estado = options.marcar.completado
        ? 3 // COMPLETADO
        : options.marcar.rechazado
        ? 4 // RECHAZADO
        : 2 // ASIGNADO;

      for (const pedidoInfo of selectedPedidos) {
        const pedido = pedidos.find((pedido) => pedido.origin_id === pedidoInfo.origin_id);
        if (pedido) {
          const idUser = options.assignar.habilitado
            ? options.assignar.user
            : userId;
          await actualizarPedido(pedido, idUser, estado);
        }
      }
      setSelectedPedidos([]);
      setMassEditMode(false);
    }

    dispatch({type: 'loading/set', payload: false});
    setModalMassiveActionsOpen(false);
    setSelectAllChecked(false);
    onFilterChange(page)
  };

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);

    if(filtersVisible == true) {
      const isFilterEmpty = Object.values(filterValues).every(x => x === null || x === '');

      if(!isFilterEmpty) clearFilters(); // Clear filters when toggling if are filled
    }
  };

  const handleSelectAll = (event) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    if (checked) {
      const allPedidoIds = filteredPedidos.map((pedido) => pedido.origin_id);
      setSelectedPedidos(allPedidoIds);
    } else {
      setSelectedPedidos([]);
    }
  };

  const FilterButtons = () => {
    const renderFilterControls = () => (
      <div className="d-flex flex-wrap flex-md-nowrap align-items-center mt-3 mt-md-0">
        <div className='form-group mb-3 mb-md-0 me-0 me-md-3'>
          <input type='text'
            name='id'
            className='form-control me-0 me-md-2'
            placeholder='Filtrar por ID'
            value={filterValues.id}
            onChange={handleFilterChange}
          />
        </div>
        <div className='form-group mb-3 mb-md-0 me-0 me-md-3'>
          <input type='text'
            name='numero_presupuesto'
            className='form-control me-0 me-md-2'
            placeholder='Filtrar por Nº Presupuesto'
            value={filterValues.numero_presupuesto}
            onChange={handleFilterChange}
          />
        </div>
        <CustomDatePicker 
          disableFuture={false}
          disablePast={false}
          onChangeDate={(date) => handleFilterChange({target : {name: 'fecha', value: date}})}
        />

        <button className="btn btn-info col-12 col-md-3 col-lg-2 mt-3 mt-md-0 mx-0 mx-md-3" onClick={clearFilters}>
          Limpiar Filtros
        </button>
      </div>
    );

    const renderMassEditButtons = () => (
      <>
        {massEditMode ? (
          <>
            <button
              className="btn btn-primary col-12 col-md-5 col-lg-4 col-xl-2"
              style={{ backgroundColor: '#CB4335' }}
              onClick={toggleMassEditMode}
              disabled={!isAdmin}
            >
              Cancelar Edición Masiva
            </button>
            <button
              className="btn btn-success col-12 col-sm-4 col-md-5 col-lg-4 col-xl-2 ms-0 ms-sm-3 mt-3 mt-sm-0"
              onClick={handleExecuteMassiveAction}
              disabled={!isAdmin || selectedPedidos?.length === 0}
            >
              Seleccionar Acción Masiva
            </button>
          </>
        ) : (
          <button
            className="btn btn-primary col-12 col-sm-5 col-md-6 ms-0 ms-sm-3 mt-3 mt-sm-0"
            onClick={toggleMassEditMode}
            hidden={!isAdmin}
          >
            Acción Masiva
          </button>
        )}
      </>
    );

    return (
      <div className="d-flex flex-wrap flex-xl-nowrap justify-content-start justify-content-sm-between mb-3 mt-2">
        <div className={'col-12 d-flex flex-wrap flex-lg-nowrap align-items-center ' + (!massEditMode && !filtersVisible ? 'col-md-6 col-lg-5 col-xl-3' : filtersVisible ? 'col-md-3 col-lg-2 col-xl-1 mb-md-3 mb-lg-0' : 'col-md-8 col-lg-9')}>
          {!massEditMode && (
            <button className={"btn btn-primary " + (filtersVisible ? 'col-12' : 'flex-grow-1')} onClick={toggleFilters}>
              {filtersVisible ? 'Ocultar Filtros' : 'Filtrar'}
            </button>
          )}
          {!filtersVisible && renderMassEditButtons()}
        </div>
        {filtersVisible && renderFilterControls()}

        { (id === 'general') &&
          <div className='col-12 col-md-4 col-lg-3 col-xl-2 mt-3 mt-xl-0'>
            <select 
              name='estado_id' id='estado_id' 
              value={filterStatus}
              onChange={(e) => handleChangeStatus(e)}
            >
              { estados.filter(e => e.id <= 3)
                .map(estado => <option key={'estado-' + estado.id} value={estado.id}>{estado.nombre}</option>)
              }
              <option value={0}>TODOS</option>
            </select>
          </div>
        }
      </div>
    );
  };

  const handleOpenSelectAlbaranLanguage = (pedido, pieza) => {
    setSelectedPedido(pedido);
    setSelectedPieza(pieza);
    setOpenSelectAlbaranLanguage(true);
  }
  const handleCloseSelectAlbaranLanguage = () => {
    setOpenSelectAlbaranLanguage(false);
    setSelectedPedido(null);
    setSelectedPieza(null);
  }
  const handelSelectAlbaranLanguage = (language) => {
    crearAlbaranPedido(selectedPedido, selectedPieza.id_referencia_plataforma, language)
  }
  

  return (
    <div className="w-100">
      <div className='w-100'>
        {FilterButtons()}

        <div className="w-100 d-flex flex-column align-items-end content-table presupuestos">
          <div className="w-100 table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th className="text-center">
                    {massEditMode && (
                      <div className='d-flex align-items-center'>
                        <Checkbox
                          color='success'
                          checked={selectAllChecked}
                          onChange={handleSelectAll}
                          disabled={!isAdmin}
                          className='p-0'
                        />
                          <b>Todos</b>
                      </div>
                      )}
                  </th>
                  <th>#</th>
                  <th className='text-center'>fecha</th>
                  <th className='text-center'>estado</th>
                  <th className='text-center'>ubicación</th>
                  <th>pieza</th>
                  <th className='text-center'>etiqueta</th>
                  <th></th>
                </tr>
              </thead>

                {filteredPedidos?.length === 0 ? (
                  <tbody>
                    <tr>
                      <td colSpan={8} className="text-center">
                        No hay pedidos pendientes
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  filteredPedidos?.map((pedido, index) => {
                    const piezasValues = Object.values(pedido.piezas)
                    const piezasRows = piezasValues.filter(p => id === 'historico' ? true : !p.cancelada).map((pieza, i) => {
                      
                      const massive = i === 0 ?
                        <td rowSpan={piezasValues.length + 1} width={'5%'} className="text-center">
                          {massEditMode ? (
                            <Checkbox
                              color='success'
                              checked={selectedPedidos.find(p => p.origin_id === pedido.origin_id)}
                              onChange={() => {
                                handleSelectPedido(pedido);
                              }}
                              disabled={!isAdmin}
                            />
                            ) : ''
                          }
                        </td>
                      : null;
                      const presupuesto = i === 0 ? <td rowSpan={piezasValues.length + 1} width="8%">
                        <div className="d-flex flex-column">
                          <span>
                            { pedido.flyer ?
                              <span className='me-2'>
                                <FontAwesomeIcon icon={faBook} size="1x" color="#215732" />
                              </span>
                              : null
                            }
                            {pedido.numero_presupuesto}
                          </span>

                          <span className='fw-bold text-uppercase text-info'>{pedido?.plataforma ? pedido.plataforma.nombre : ''}</span>
                        </div>
                        

                      </td> : null
                      const fecha = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className="text-center">
                        {moment(pedido.fecha).tz('Europe/Madrid').format('DD-MM-YYYY HH:mm:ss')}
                      </td> : null
                      const estado = i === 0 ?  <td rowSpan={piezasValues.length + 1} width="12%" className='text-center text-uppercase'>
                        <span className='d-flex flex-column'>
                          <span className={'fw-bold ' + (getStatusPedido(pedido.estado_id))}>{pedido.estado.nombre}</span>
                          { (pedido.estado_id === 3) &&
                            <small>{pedido?.responsable?.username}</small>
                          }
                        </span>
                      </td> : null
                      const acciones = i === 0 ?  <td rowSpan={piezasValues.length + 1} className="text-center">
                        { (getActions(pedido, id, currentUser.rol_id === 1)?.length > 0) ?
                          <ActionsMenu
                            options={getActions(pedido, id, currentUser.rol_id === 1)}
                            onAction={(action) => handleAction(action, pedido)}
                          />
                          : null
                        }
                        
                      </td> : null
                            
                      return (
                        <tr key={i}>
                          {massive}
                          {presupuesto}
                          {fecha}
                          {estado}
                          <td className='text-center'>
                            <span className='fw-bold'>{pieza.ubicacion}</span>
                          </td>
                          <td>
                            <div className='w-100 d-flex align-items-center'>
                              <div className='flex-grow-1 d-flex flex-column'>
                                {pieza.cancelada ? <small className='fw-bold text-danger'>CANCELADA</small> : ''}
                                <span
                                  onClick={() => handleOpenModalPieza(pieza)}
                                  style={{
                                    cursor: 'pointer',
                                    color: '#215731',
                                  }}
                                >
                                  { (dashboard.logs_seinto?.find(ls => ls.pieza_id === pieza.id)) ?
                                      <span className='me-2' title={"No se ha podido actualizar el estado"}>
                                        <FontAwesomeIcon icon={faExclamationTriangle} size="1x" color="#dc3545" />
                                      </span>
                                      : null
                                  }
                                  {pieza.nombre}
                                </span>
                                <span className='fw-bold'>#{pieza.id_referencia_plataforma}</span>
                                <span>REF: {pieza.referencia_pieza}</span>
                                { (id === 'historico' && pedido.estado_id === 5) ?
                                  <span className='mt-1' style={{cursor: 'pointer'}} onClick={() => handleOpenSelectAlbaranLanguage(pedido, pieza)}>
                                    <PictureAsPdf />
                                  </span>
                                  : ''
                                }
                                <span>
                                  <span className='fw-bold text-info me-1'>{pieza?.transportista?.nombre}</span>
                                  <span>
                                    Hora de entrega:
                                    <span className='fw-bold ms-1'>{pieza.fecha_recogida ? moment(pieza.fecha_recogida).format('DD-MM-YYYY HH:mm:ss') : '-'}</span>
                                  </span>
                                </span>
                                
                              </div>
                              <div className='d-flex justify-content-end'>
                                <span
                                  onClick={() => handleOpenModalPieza(pieza)}
                                  style={{
                                    cursor: 'pointer',
                                    color: '#215731',
                                  }}
                                >
                                  <PiezaPreview
                                    pieza={pieza}
                                    onOpenModal={handleOpenModalPieza}
                                  />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="text-center">
                            {pieza.etiqueta_envio ? (
                              <Print style={{ color: 'green' }} />
                            ) : (
                              <Print style={{ color: '#ffc107' }} />
                            )}
                          </td>
                          {acciones}
                        </tr>
                      )
                    })

                    return (
                      <tbody key={index}>
                        {piezasRows}
                      </tbody>
                    )
                  })
                )
              }
            </table>
          </div>

          <Pagination 
            className="mt-3" 
            count={getPaginationCount()} 
            page={page} 
            onChange={handleChangePage} 
          />
        </div>

        { (modalPiezaOpen) &&
          <PiezaModal
            pieza={selectedPieza}
            state={modalPiezaOpen}
            width="sm"
            onClose={handleCloseModalPieza}
          />
        }

        { (modalUserOpen) &&
          <InfoModal
            title={"Selecciona el usuario al que quieres asignar el pedido"}
            content={
              <UserSelector
                rolId={2}
                onAssign={handleAsignarUsuario}
              />
            }
            state={modalUserOpen}
            width={"sm"}
            onClose={handleCloseModalUser}
          />
        }

        { (uploadModalOpen) &&
          <InfoModal
            title={"Subir etiqueta para pedido " + selectedPedido.numero_presupuesto}
            content={
              <EtiquetaForm 
                onSubmitEtiqueta={handleSaveEtiqueta}
              />
            }
            state={uploadModalOpen}
            width={"sm"}
            onClose={handleCloseUploadModal}
          />
        }

        { (openSelectAlbaranLanguage) &&
          <InfoModal
            title={"Imprimir albaran de entrega " + selectedPedido.numero_presupuesto}
            content={
              <LanguageSelector 
                onSelectLanguage={handelSelectAlbaranLanguage}
              />
            }
            state={openSelectAlbaranLanguage}
            width={"sm"}
            onClose={handleCloseSelectAlbaranLanguage}
          />
        }

        { (pdfViewerOpen) &&
          <PdfViewerModal
            pedido={selectedPedido}
            state={pdfViewerOpen}
            width="md"
            onClose={handleClosePdfViewer}
          />
        }

        { (modalMassiveActionsOpen) &&
          <MassiveActionsModal
            from={id}
            state={modalMassiveActionsOpen}
            selectedPedidos={selectedPedidos}
            isAdmin={currentUser.rol_id === 1}
            width="sm"
            onClose={handleCloseModalMassiveActions}
            onReturn={(options) => handleFinalizeMassiveAction(options)}
          />
        }

        { (confirmModalOpen) &&
          <ConfirmModal
            title="Confirmar acción"
            description="Este pedido tiene más de una pieza, ¿desea continuar con la acción?"
            state={confirmModalOpen}
            onConfirmAction={handleConfirmAction}
            onClose={() => setConfirmModalOpen(false)}
          />
        }

        { (confirmDesasignModalOpen) &&
          <ConfirmModal
            title="Desasignar pedido"
            description="Estás seguro de desasignarte el pedido?"
            state={confirmDesasignModalOpen}
            onConfirmAction={handleConfirmDesasignAction}
            onClose={() => setConfirmDesasignModalOpen(false)}
          />
        }

        { (openConfirmDeleteEtiqueta) &&
          <ConfirmModal
            title={`Eliminar etiqueta del pedido ${selectedPedido.numero_presupuesto}`}
            description="Estás seguro de eliminar la etiqueta de envío?"
            state={openConfirmDeleteEtiqueta}
            onConfirmAction={handleDeleteEtiqueta}
            onClose={handleCloseDeleteEtiqueta}
          />
        }

        { (openLogs) &&
          <InfoModal
            title={"Logs de estado de " + selectedPedido.numero_presupuesto}
            content={
              <LogsTable 
                logs={selectedPedido.logs}
              />
            }
            state={openLogs}
            width={"sm"}
            onClose={handleCloseLogs}
          />
        }

        { (openAssignTransportista) &&
          <InfoModal
            title={"Asignar transportista a " + selectedPedido.numero_presupuesto}
            content={
              <TransportistaSelector 
                onSelectTransportista={handleAssignTransportista}
              />
            }
            state={openAssignTransportista}
            width={"sm"}
            onClose={handleCloseAssignTransportista}
          />
        }

        { (openCancelarPiezas) &&
          <InfoModal
            title={"Cancelar pieza/s del pedido " + selectedPedido.numero_presupuesto}
            content={
              <PiezasSelector
                pedido={selectedPedido} 
                onSubmitCancelarPiezas={handleCancelarPiezas}
              />
            }
            state={openCancelarPiezas}
            width={"sm"}
            onClose={handleCloseCancelarPiezas}
          />
        }
      </div>
    </div>
  )
}
